// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-4!../../node_modules/cesium/Source/Widgets/InfoBox/InfoBoxDescription.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ib h3 {
    text-align: center;
    margin-block-start: 10px;
    margin-block-end: 10px;
}

.ib .ib-text {
    text-align: center;
}

.ib .ib-code {
    background: #f4f4f4;
    color: #666;
    font-size: 12px;
    line-height: 1.5;
    margin: 0px;
    padding: 4px;
    white-space: pre-wrap;
}

.ib .ib-code code {
    background: inherit;
}

.ibt {
    border-collapse: collapse;
    border-radius: 6px;
    width: 100%;
}

.ibt th, .ibt td {
    border: 1px solid #6d6d6d;
}

.ibt th {
    background-color: #4CAF50;
    padding: 6px;
}

.ibt td {
    text-align: center;
    padding: 4px;
}

.ibt td:first-child, .ibt th:first-child {
    text-align: left;
}

.ibt .ibt-right {
    text-align: right;
}

.cesium-infoBox-description {
    margin-right: 0px;
    min-height: 200px;
    max-height: 500px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
`, "",{"version":3,"sources":["webpack://./src/css/infobox.ecss"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;AACrC","sourcesContent":["@import url(\"../../node_modules/cesium/Source/Widgets/InfoBox/InfoBoxDescription.css\");\n\n.ib h3 {\n    text-align: center;\n    margin-block-start: 10px;\n    margin-block-end: 10px;\n}\n\n.ib .ib-text {\n    text-align: center;\n}\n\n.ib .ib-code {\n    background: #f4f4f4;\n    color: #666;\n    font-size: 12px;\n    line-height: 1.5;\n    margin: 0px;\n    padding: 4px;\n    white-space: pre-wrap;\n}\n\n.ib .ib-code code {\n    background: inherit;\n}\n\n.ibt {\n    border-collapse: collapse;\n    border-radius: 6px;\n    width: 100%;\n}\n\n.ibt th, .ibt td {\n    border: 1px solid #6d6d6d;\n}\n\n.ibt th {\n    background-color: #4CAF50;\n    padding: 6px;\n}\n\n.ibt td {\n    text-align: center;\n    padding: 4px;\n}\n\n.ibt td:first-child, .ibt th:first-child {\n    text-align: left;\n}\n\n.ibt .ibt-right {\n    text-align: right;\n}\n\n.cesium-infoBox-description {\n    margin-right: 0px;\n    min-height: 200px;\n    max-height: 500px;\n    overflow-y: auto;\n    -webkit-overflow-scrolling: touch;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
