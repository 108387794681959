<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import "@cesium/widgets/Source/widgets.css";
import "./css/main.css";

export default {
};
</script>
